.loadingBackdrop {
  z-index: 4 !important;
}

.mapLoading {
  position: absolute;
  top: 48px;
  right: 0;
  background-color: rgba(255, 255, 255, 0.6);
  text-align: right;
  width: fit-content;
  font-size: 14px;
  padding: 20px 10px;
}

.mapLoading > ul {
  list-style: none;
  padding-right: 20px;
}

.mapLoading > ul > li {
  padding: 4px 0;
}

.mapLoading span {
  margin-left: 10px;
}

.splash {
  /* width: 100%; */
  box-sizing: border-box;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: #f0f2f5;
  text-align: center;
}

.splash img {
  display: block;
  animation: pseudoSpin 1s linear infinite alternate;
}

.App {
  display: flex;
  min-height: 100%;
}

.App main {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 48px;
  position: relative;
  width: calc(100% - var(--drawerWidth));
}

@keyframes pseudoSpin {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}
