.data-panel {
  background-color: rgba(228, 228, 228, 0.9);
  padding: 8px 8px;
  display: flex;
  gap: 16px;
  font-size: 14px;
  align-items: center;
  border-radius: 8px;
}

.data-panel > .bordered {
  padding-right: 16px;
  border-right: 1px solid #aaa;
}

.geo-stats .label,
.panel-button .label {
  font-size: 10px;
  color: #111;
}

.geo-stats {
  display: flex;
  align-items: center;
  gap: 12px;
}

.data-panel .push-right {
  margin-left: auto;
}

.data-panel .panel-button:nth-child(2) {
  min-width: 40px;
}

.data-panel .points-legend {
  position: absolute;
  top: -40px;
}

.data-panel .points-legend .MuiButton-root {
  text-transform: none;
  color: #3f51b5;
}

.geo-stats > div > span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.geo-stats .county {
  width: 100px;
}

.geo-stats .metric .label {
  min-height: 24px;
}

.geo-stats .zipcode {
  width: 50px;
  font-size: 16px;
}

.metric {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  flex-grow: 0;
  width: 50px;
}

.data-panel .panel-button {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.data-panel .panel-button button {
  width: fit-content;
  align-self: center;
}

.data-panel .panel-button .MuiToggleButton-sizeSmall {
  padding: 4px;
  color: black;
}
.data-panel .panel-button .MuiToggleButton-root.Mui-selected {
  background-color: #3f51b5;
  color: white;
}

.data-panel .panel-button .MuiToggleButton-root {
  background-color: white;
}

.data-panel .map-legend {
  font-size: 12px;
}

.mapOverview .MuiDialog-root .MuiDialog-container {
  margin-left: var(--drawerWidth);
}

.data-panel .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
  border-color: #ccc !important;
}
