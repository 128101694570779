.App header {
  z-index: 3;
  background-color: #213250;
  width: calc(100% - 186px);
  width: calc(100% - var(--drawerWidth));
  margin-left: 186px;
  margin-left: var(--drawerWidth);
}

.App .MuiToolbar-regular.custom {
  min-height: 48px;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
}

.App .MuiToolbar-regular.custom.dense {
  padding-left: 8px;
  padding-right: 8px;
}

.gaps-changes {
  color: #fff;
}

.gaps-changes {
  display: flex;
  align-items: center;
  margin-left: 12px;
}
.gaps-changes > p {
  margin-right: 4px;
}

.gaps-changes strong {
  color: #f4a460;
  font-size: larger;
}

.gaps-changes .MuiIconButton-root {
  color: inherit;
}

.gaps-changes .MuiIconButton-root:hover {
  background-color: rgba(255, 255, 255, 0.12);
}

.gaps-changes .MuiIconButton-root .MuiSvgIcon-root {
  margin-right: 0;
}

.gaps-changes .MuiSvgIcon-root {
  margin-right: 12px;
}

.changes-dialog {
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* network selector */

.network-selector {
  height: 39px;
  background: #e0dede;
  background: white;
  display: flex;
}

.network-selector .MuiSelect-select {
  font-size: 0.8rem;
  padding: 0 10px;
  padding-right: 24px !important;
}

.network-selector .MuiSvgIcon-root {
  right: 0;
}

/* specialty selector */
.specialty-selector {
  height: 39px;
  background: rgb(224, 222, 222);
  background: white;
  display: flex;
  margin-left: 16px;
}

.specialty-selector .MuiTypography-root {
  font-size: 0.8rem;
}

.specialty-selector .MuiSelect-selectMenu {
  min-height: initial;
}

.specialty-selector .MuiMenuItem-root {
  padding: 0 8px;
  line-height: 1;
  height: 100%;
}

.specialty-selector .MuiListItem-root.Mui-selected,
.specialty-selector .MuiListItem-root.Mui-selected:hover {
  background-color: rgba(255, 255, 255, 0.08);
  /* background-color: white; */
}

.specialty-selector .MuiInputBase-input {
  padding: 0;
}

.scoreListRow,
.scoreListRow {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: transparent !important;
  /* border: 3px solid transparent; */
  padding: 0;
}

.scoreListRow:hover {
  background-color: transparent;
}

.MuiMenu-list .MuiTypography-root {
  font-size: 0.9rem;
}

.specialty-selector .MuiListItemIcon-root {
  display: none;
}

.MuiMenu-list .MuiListItemIcon-root {
  font-size: 0.9rem;
  line-height: 1;
  min-width: 44px !important;
}

.MuiMenu-list > .MuiMenuItem-root:not(:last-child) {
  border-bottom: 1px solid lightgrey;
}

.MuiMenu-list .MuiListItemButton-root {
  padding: 0;
}

.specialty-selector .fail-gaps {
  padding: 8px;
}

.fail-gaps {
  background-color: #f2aeae;
  padding: 12px;
  border-radius: 10px;
  color: #111;
  font-weight: 700;
}

.pass-gaps {
  color: green;
  padding: 6px;
  font-size: 2rem;
}

/* county stats */

.county-stats {
  margin-left: 40px;
  margin-right: 8px;
  margin-top: 10px;
}

.county-stats .label {
  font-size: 10px;
  color: #aaa;
  position: absolute;
  top: 2px;
}

.county-stats-details {
  margin-top: 2px;
  font-size: 12px;
  white-space: nowrap;
}

.county-stats-details > * {
  line-height: 0;
}

.county-stats-details .region {
  margin-right: 6px;
}

.county-stats-details .gap {
  color: sandybrown;
}

/* provider cart */
.provider-cart {
  display: flex;
  position: relative;
  text-align: center;
  margin-left: 24px;
}

.provider-cart .changes {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.provider-cart .changes svg {
  display: block;
  cursor: pointer;
}

.positive-changes {
  font-size: 1.2rem;
  padding: 3px;
  border-radius: 20px;
  background-color: #fff7a1;
  cursor: pointer;
}

.negative-changes {
  font-size: 1.2rem;
  padding: 3px;
  border-radius: 20px;
  background-color: #fff7a1;
  cursor: pointer;
}

.MuiToolbar-regular .provider-cart .changes span {
  font-size: 1rem;
  padding: 8px;
}

.MuiToolbar-regular .provider-cart .negative-changes,
.MuiToolbar-regular .provider-cart .positive-changes {
  background-color: tomato;
  font-weight: 700;
}

.MuiToolbar-regular .provider-cart .undo-icon {
  margin-left: 4px;
}

.MuiToolbar-regular .provider-cart .MuiSvgIcon-colorPrimary {
  color: #fff;
}

.MuiToolbar-regular .provider-cart strong {
  color: #fff;
}
