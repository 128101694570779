.tableCell.pass {
  background-color: #81c784;
}
.tableCellData {
  padding-top: 4px;
  padding-bottom: 4px;
  border: 1px solid transparent;
}
.tableCellData.fail {
  color: rgb(255, 43, 43);
  font-weight: bolder;
  font-size: 15px;
}

.tableCell {
  border: 2px solid #e5e5e5;
}
.scoreTableCell {
  border-left: 2px solid #aaa;
  padding: 0px !important;
}
.scoreTableCell:last-child {
  border-right: 2px solid #aaa;
}
.tableCellBody {
  display: flex;
  justify-content: space-around;
}
.tableCellHead {
  border: 3px dashed #eee;
  padding: 0;
  margin: 0;
}

.tableCellData {
  min-width: 80px;
}

.tableOverrides table {
  display: block !important;
}

.tableOverrides [data-id="-1"] {
  background-color: lightgoldenrodyellow;
}

.tableOverrides .MuiDataGrid-row.Mui-hovered[data-id="-1"] {
  background-color: lightgoldenrodyellow;
}

.specialty-cell {
  font-weight: bold;
  position: relative;
  font-size: 1rem !important;
  justify-content: flex-start !important;
}

.gaps-cell {
  font-weight: bold;
  font-size: 16px !important;
  width: 100px;
  min-width: 100px;
  padding: 0 !important;
  flex-direction: column;
  display: flex !important;
  justify-content: center;
}

.gaps-cell--pass {
  color: green;
  font-size: 24px !important;
}

.county-cell {
  padding: 0 !important;
}

.county-cell__inner {
  border-left: 2px solid #aaa;
  width: 100%;
  height: 100%;
  padding: 0 10px;
}
.county-cell__inner > div {
  height: 100%;
  text-align: center;
  position: relative;
}

.county-cell:has(.gap-down) {
  background-color: rgb(129, 199, 132, 0.2);
}

.county-cell:has(.gap-up) {
  background-color: rgba(255, 99, 71, 0.12);
}

.county-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  line-height: 1.5;
  text-align: center;
}

.specialty-header,
.gaps-header {
  font-size: 20px;
}

.attributes-header {
  width: 100%;
}

.tableOverrides
  .MuiDataGrid-row:not([data-rowindex="0"]):hover
  .MuiDataGrid-cell.county-cell {
  cursor: pointer;
}

.tableOverrides
  .MuiDataGrid-row:not([data-rowindex="0"]):hover
  .MuiDataGrid-cell.gaps-cell:not(:has(.gaps-cell--pass)) {
  cursor: pointer;
}

.tableOverrides
  .MuiDataGrid-row:not([data-rowindex="0"])
  .MuiDataGrid-cell.county-cell:hover {
  outline: 2px solid gray;
  outline-offset: -2px;
}
.cellPopper {
  background-color: white;
  border: 1px solid gray;
  padding: 16px 0;
}

.cellPopper .MuiListItemIcon-root {
  min-width: 32px;
  padding: 0;
}
