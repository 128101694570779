.modal-popup-main {
  width: 50%;
  margin: 0 auto;
  margin-top: 10vh;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-popup-main .providerPopup {
  overflow: auto;
  width: 100%;
  height: 80vh;
  text-align: left;
  font-size: 14px;
  background-color: white;
  box-shadow: 0px 0px 3px 3px #444;
}

.modal-popup-main .providerPopupSection {
  padding: 10px 10px 40px 10px;
}

.providerPopupActionButton {
  margin: 0 auto;
  text-align: center;
}

.providerHeader {
  position: relative;
}

.providerPopup {
  position: relative;
  box-sizing: border-box;
}

.providerPopup .fields {
  padding: 0;
  margin: 16px 0;
}

.providerPopup .field {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 6px 0;
}

.providerPopup .label {
  flex-shrink: 0;
}

.providerPopup .value {
  font-weight: bold;
  margin-left: 8px;
}

.providerPopup .adequacy_per_county .value > div {
  flex-wrap: wrap;
  gap: 8px;
}

.providerPopup .adequacy_per_county .value > div > * {
  margin: 0;
}

.providerPopup .legend-button {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
