body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#root {
  height: 100vh;
}

body {
  height: 100%;
}

img {
  display: block;
}

button {
  cursor: pointer;
}

.list {
  margin-right: 50px;
  height: 500px;
  overflow: scroll;
}

.selected {
  font-weight: bold;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.mapOverview {
  --mapSplit: 68vh;
}

.interactiveMap {
  height: calc(var(--mapSplit) - 40px);
}

.mapContainer {
  height: 100%;
}

.floating-bar {
  border-radius: 0 10px 10px 10px;
  position: absolute;
  z-index: 3;
  bottom: calc(100vh - var(--mapSplit) + 6px);
  left: 8px;
  display: flex;
}

.providerGrid {
  position: relative;
  height: calc(100vh - var(--mapSplit));
}

.providerGrid > div {
  height: 100%;
}

div.mapboxgl-ctrl-bottom-left {
  left: auto;
  right: 8px;
}

.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
  float: right;
}

.full-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

[data-field="likability"] .MuiButton-root {
  color: inherit !important;
  border: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
