.account .MuiListItemAvatar-root {
  min-width: 38px;
}

.avatar {
  height: 32px;
  width: 32px;
}

.logout-menu .avatar {
  height: 40px;
  width: 40px;
}

.logout-menu .MuiPaper-root {
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.logout-button {
  margin-right: 16px;
}
