:root {
  --drawerWidth: 186px;
}

.logo {
  margin: 16px auto;
}

.app-drawer-main {
  overflow: auto;
  background: 
    /* cover top - when not scrolled */ linear-gradient(
        #efefef,
        #efefef
      )
      center top,
    /* cover bottome - when not scrolled */ linear-gradient(#efefef, #efefef)
      center bottom,
    /* when scrolled down*/ linear-gradient(#ddd, #efefef) center top,
    /* when scrolled up*/ linear-gradient(#efefef, #ddd) center bottom;

  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 8px, 100% 8px;
  background-attachment: local, local, scroll, scroll;
}

.app-drawer a {
  text-decoration: none;
  color: inherit;
}

.MuiDrawer-root {
  flex-shrink: 0;
  z-index: 3;
}

.MuiDrawer-root,
.MuiDrawer-paper {
  width: var(--drawerWidth);
}

div.MuiDrawer-paper {
  background-color: #efefef;
}

.MuiDrawer-paper .MuiListItemIcon-root {
  color: #213250;
}

.MuiDrawer-paper .MuiListItemIcon-root {
  min-width: 32px;
}

.app-drawer .account-box {
  margin-top: auto;
}
