.providerGrid {
  position: relative;
}

.providerGrid-loading {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
